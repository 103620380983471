import { defineStore } from 'pinia'
import repositoryService from '@/stores/services/repository.services'

const PREFIX = 'efficientsolutions/'

export const useClientStore = defineStore("client", {
    state: () => ({
        clientList: [],
        client: {}
    }),
    getters: {
        itemClient: (state) => state.client,
        clients: (state) => state.clientList
    },
    actions: {
        create(data) {
            try {
                let entity = `${PREFIX}client`
                return repositoryService.post(data, entity).then(
                    response => {
                        return Promise.resolve(response.status)
                    },
                    () => {
                        return Promise.reject(false)
                    }
                );
            } catch (error) {
                console.error(error);
            }
        },
        getClients() {
            try {
                let entity = `${PREFIX}client`
                return repositoryService.get(entity).then(
                    response => {
                        this.clientList = response.data.data
                        return Promise.resolve(response.status)
                    },
                    () => {
                        this.clientList = []
                        return Promise.reject(false)
                    }
                );
            } catch (error) {
                console.error(error);
            }
        },
        getClient(uuid) {
            try {
                let entity = `${PREFIX}client/${uuid}`
                return repositoryService.get(entity).then(
                    response => {
                        this.client = response.data.data
                        return Promise.resolve(response.status)
                    },
                    () => {
                        this.client = {}
                        return Promise.reject(false)
                    }
                );
            } catch (error) {
                console.error(error);
            }
        },
        searchRfc(rfc) {
            try {
                let entity = `${PREFIX}client/rfc/${rfc}`
                return repositoryService.get(entity).then(
                    response => {
                        this.client = response.data.data
                        return Promise.resolve(response.status)
                    },
                    () => {
                        this.client = {}
                        return Promise.reject(false)
                    }
                );
            } catch (error) {
                console.error(error);
            }
        },
        delete(item) {
            try {
                let entity = `${PREFIX}client`
                return repositoryService.delete(item, entity).then(
                    response => {
                        return Promise.resolve(response.status)
                    },
                    () => {
                        return Promise.reject(false)
                    }
                );
            } catch (error) {
                this.userData = {}
                console.error(error);
            }
        }
    },
})