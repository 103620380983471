import { defineStore } from 'pinia'

export const useCommonStore = defineStore("commons", {
    state: () => ({
        items: [],
        isBussy: false,
    }),
    getters: {
        getItems: (state) => state.items,
    },
    actions: {
        setItems(items) {
            this.items = items;
        },
        setLoader(mode){
            this.isBussy = mode
        }
    },
})