<template>
  <div id="app">
    <Toasts class="toast" />
    <w-overlay v-model="loginShowOverlay" bg-color="rgba(35, 71, 129, 0.4)">
      <w-flex column align-center justify-center class="wrapper">
        <img src="./assets/animations/efs-animation.gif" id="image">
        <div class="lds-ellipsis mt-7">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </w-flex>
    </w-overlay>
    <w-overlay v-model="showOverlay" bg-color="rgba(35, 71, 129, 0.4)">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </w-overlay>
    <w-app>
      <router-view />
    </w-app>
  </div>
</template>

<script>
import { Toasts } from "@/components";
import { useCommonStore } from '@/stores/commons'
import { useAccountStore } from '@/stores/accounts'
import { useMessagingStore } from '@/stores/messaging'
import { app } from "./firebase";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
export default {
  components: {
    Toasts
  },
  created() {
    console.log(process.env.VUE_APP_TITLE);
    const messaging = getMessaging(app);
    getToken(messaging, {
      vapidKey:
        "BEUbGzgZCxS0iuhyql2agcdJKdGGeklHLjz4KxIibTlGAihy4tIk6BiIbAoADiObl6J3BJB8GCEi_nLP2MfGjmo",
    })
      .then((currentToken) => {
        if (currentToken) {
          console.log("TOKEN ID FMC FOR THIS BROWSER");
          console.log(currentToken);
          let msgStore = useMessagingStore()
          msgStore.setToken(currentToken)
          //this.$store.dispatch("messaging/set_token", currentToken);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });

    onMessage(messaging, (payload) => {
      console.log("onMessage FMC", payload)
    });
  },
  computed: {
    showOverlay() {
      const store = useCommonStore();
      return store.isBussy
    },
    loginShowOverlay() {
      const lgStore = useAccountStore();
      return lgStore.isBussy
    }
  }
}
</script>
<style>
#app {
  text-align: left;
  top: 0;
  margin: 0;
  padding: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 400;
  letter-spacing: .075em;
  color: #777777;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
}

.section-component {
  height: 100%;
}

.toats,
.dialog-z-index {
  z-index: 999999999999 !important;
}

.mySwal {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
}

.w-table--mobile .w-table__cell:before {
  width: 8em;
}

.w-overlay {
  backdrop-filter: blur(10px);
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #f27528;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

/* Background colour of an input field with value */
.otp-input.is-complete {
  background-color: #e4e4e4;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}

.w-table>th {
  text-align: center !important;
}

.steppy-efs,
.steppy-pane {
  padding: 60px !important;
  height: 50vh;
}


/* Cuando la pantalla tenga 600px o menos, cambia el padding a 30px */
@media (max-width: 600px) {

  .steppy-efs,
  .steppy-pane {
    padding: 30px !important;
  }
}

/* TRANSICIONES PERSONALIZADAS */
.bounce-enter-active {
  animation: bounce-in 1.3s;
}

.bounce-leave-active {
  animation: bounce-in 1.3s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale (0);
  }

  50% {
    transform: scale (1.8);
  }

  100% {
    transform: scale (1);
  }
}</style>
