import { defineStore } from 'pinia';

export const useToastStore = defineStore({
    id: 'toast',
    state: () => ({
        toasts: []
    }),
    actions: {
        saved201() {
            let toast = {
                title: "Registro Exitoso",
                type: "success",
                message: "El registro fué creado con éxito."
            }
            this.toasts.push(toast)
        },
        updated() {
            let toast = {
                title: "Actualizaci{on Exitosa",
                type: "success",
                message: "El registro fué actualizado con éxito."
            }
            this.toasts.push(toast)
        },
        deleted() {
            let toast = {
                title: "Borrado Exitoso",
                type: "success",
                message: "El registro fué eliminado con éxito."
            }
            this.toasts.push(toast)
        },
        // ERRORES
        tokenExpired() {
            console.log("entro al error 409")
            let toast = {
                title: "Sesión expirada",
                type: "warning",
                message: "El token asignado ha caducado, deberá iniciar sesión de nuevo."
            }
            this.toasts.push(toast)
        },
        error409(msg) {
            let toast = {
                title: "Datos incorrectos",
                type: "error",
                message: msg
            }
            this.toasts.push(toast)
        },
        emailRepeat() {
            let toast = {
                title: "Cuenta existente",
                type: "error",
                message: "Ya existe una cuenta creada con estos datos."
            }
            this.toasts.push(toast)
        },
        clearToast(title){
            const index = this.toasts.findIndex((toast) => toast.title === title)
            this.toasts.splice(index, 1)
        }
    }
});