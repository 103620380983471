<template>
    <div class="mt0">
        <w-dialog v-model="dialogAdd" fullscreen="true" transition="slide-up"
            content-class="w-flex">
            <CreateClient @added="onAdded" @cancel="onCloseAdd" class="my5 fill-width" />
        </w-dialog>
        <w-toolbar shadow>
            <div class="title2">Mis Facturas</div>
            <div class="spacer"></div>
            <w-button color="secondary"  v-for="(a, i) in actionToolbar" :key="i" :icon="a.icon"
                text lg class="ml3" @click="onExecute(a.action)"></w-button>
        </w-toolbar>
        <div class="pa0">
            <w-list v-model="selection" :items="table.items" bg-color="blue-light5" color="black"
                selection-color="secondary" hover @item-click="onSetData($event)">
                <template #item="{ item }">
                    <span>{{ item.business_name }} <br> <strong> {{ item.rfc }} </strong> </span>
                </template>
            </w-list>           
        </div>
    </div>
</template>
<script>
import 'animate.css';
import Swal from 'sweetalert2'
import { useClientStore } from '@/stores/clients.js'
import CreateClient from '../clientAdd';
export default {
    components: {
        CreateClient
    },
    data: () => ({
        selection: null,
        dialogAdd: false,
        keyword: '',
        listServices: [],
        valid: null,
        form: {
            submitted: false,
            sent: false,
            errorsCount: 0
        },
        validators: {
            required: value => !!value || 'El campo es requerido',
            min: (len) => (value) => (value || "").length >= len || `El campo debe tener al menos ${len} caracteres.`,
        },
        table: {
            headers: [
                { label: 'Razon social', key: 'business_name' },
                { label: 'RFC', key: 'rfc' },
            ],
            items: []
        },
        actionToolbar: [
            { action: 'create', label: 'Agregar', icon: 'fa fa-user-plus' },
        ],
    }),
    created() {
        Swal.fire({
            title: "¿Para que Cliente sera la factura?",
            text: "Seleccione un cliente de su catalogo o registre uno nuevo",
            icon: "question"
        });
        this.getDS()
    },
    methods: {
        getDS() {
            let fStore = useClientStore();
            fStore.getClients().then(rs => {
                if (rs == 200) {
                    this.table.items = fStore.clientList
                }
            }, () => {
                Swal.fire({
                    icon: "error",
                    title: "Sin retultados",
                    text: "No se encontraron resultados para la búsqueda",
                });
            })
        },
        onExecute(p) {
            switch (p) {
                case 'create':
                    this.addClient()
                    break;
            }
        },
        addClient() {
            this.dialogAdd = true
        },
        onAdded() {
            this.dialogAdd = false
            this.getDS()
        },
        onCloseAdd() {
            this.dialogAdd = false
        },
        onSetData(p) {
            this.$emit("selected", p);
        },
        onCancel() {
            this.$emit("cancel");
            this.table.items = []
            this.keyword = ''
        },
        onEdit(p) {
            console.log("editar", p.uuid)

        },
        onDelete(p) {
            let store = useClientStore();
            Swal.fire({
                title: "<strong>Eliminar registro</strong>",
                text: "¿Confirma eliminar el registro? <br> Una vez eliminado no se podrá recuperar la información",
                icon: "warning",
                showClass: {
                    popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                    `
                },
                hideClass: {
                    popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
    `
                },
                customClass: {
                    container: "mySwal",
                },
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonText: "Si, confirmo",
                confirmButtonColor: "#348e91",
                cancelButtonColor: "#f27528",

            }).then((result) => {
                if (result.isConfirmed) {
                    console.log("eliminar", p.uuid)
                    store.delete(p).then((rs) => {
                        if (rs = 200) {
                            Swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "El registro ha sido eliminado.",
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                    }, (e) => {
                        console.error("la solicitud fallo")
                        Swal.fire({
                            icon: "error",
                            title: "Error en ejecución",
                            text: e,
                        });
                    })
                }
            });
        }
    }
}
</script>