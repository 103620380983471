export function paymentMethods() {
    return [
        { id: 1, description: "Pago en una exhibición", code: "PUE" },
        { id: 2, description: "Pago en parcialidades o diferido", code: "PPD" }
    ]
}

export function paymentForm() {
    return [
        { id: 1, code: '01', description: 'Efectivo' },
        { id: 2, code: '2', description: 'Cheque nominativo' },
        { id: 3, code: '03', description: 'Transferencia electrónica de fondos' },
        { id: 4, code: '04', description: 'Tarjeta de crédito' },
        { id: 5, code: '05', description: 'Monedero electrónico' },
        { id: 6, code: '06', description: 'Dinero electrónico' },
        { id: 7, code: '08', description: 'Vales de despensa' },
        { id: 8, code: '12', description: 'Dación en pago' },
        { id: 9, code: '13', description: 'Pago por subrogación' },
        { id: 10, code: '14', description: 'Pago por consignación' },
        { id: 11, code: '15', description: 'Condonación' },
        { id: 12, code: '17', description: 'Compensación' },
        { id: 13, code: '23', description: 'Novación' },
        { id: 14, code: '24', description: 'Confusión' },
        { id: 15, code: '25', description: 'Remisión de deuda' },
        { id: 16, code: '26', description: 'Prescripción o caducidad' },
        { id: 17, code: '27', description: 'A satisfacción del acreedor' },
        { id: 18, code: '28', description: 'Tarjeta de débito' },
        { id: 19, code: '29', description: 'Tarjeta de servicios' },
        { id: 20, code: '30', description: 'Aplicación de anticipos' },
        { id: 21, code: '31', description: 'Intermediario pagos' },
        { id: 22, code: '99', description: 'Por definir' }
    ]
}

export function cfdiType() {
    return [
        { id: 1, code: 'I', description: 'Ingreso' },
        { id: 2, code: 'E', description: 'Egreso' },
        { id: 3, code: 'T', description: 'Traslado' },
        { id: 4, code: 'N', description: 'Nómina' },
        { id: 5, code: 'P', description: 'Pago' }
    ]
}

export function cfdiUses() {
    return [
        { id: 1, code: 'G01', description: 'Adquisición de mercancías.' },
        { id: 2, code: 'G02', description: 'Devoluciones, descuentos o bonificaciones.' },
        { id: 3, code: 'G03', description: 'Gastos en general.' },
        { id: 4, code: 'I01', description: 'Construcciones.' },
        { id: 5, code: 'I02', description: 'Mobiliario y equipo de oficina por inversiones.' },
        { id: 6, code: 'I03', description: 'Equipo de transporte.' },
        { id: 7, code: 'I04', description: 'Equipo de computo y accesorios.' },
        { id: 8, code: 'I05', description: 'Dados, troqueles, moldes, matrices y herramental.' },
        { id: 9, code: 'I06', description: 'Comunicaciones telefónicas.' },
        { id: 10, code: 'I07', description: 'Comunicaciones satelitales.' },
        { id: 11, code: 'I08', description: 'Otra maquinaria y equipo.' },
        { id: 12, code: 'D01', description: 'Honorarios médicos, dentales y gastos hospitalarios.' },
        { id: 13, code: 'D02', description: 'Gastos médicos por incapacidad o discapacidad.' },
        { id: 14, code: 'D03', description: 'Gastos funerales.' },
        { id: 15, code: 'D04', description: 'Donativos.' },
        { id: 16, code: 'D05', description: 'Intereses reales efectivamente pagados por créditos hipotecarios { id: casa habitación}.' },
        { id: 17, code: 'D06', description: 'Aportaciones voluntarias al SAR.' },
        { id: 18, code: 'D07', description: 'Primas por seguros de gastos médicos.' },
        { id: 19, code: 'D08', description: 'Gastos de transportación escolar obligatoria.' },
        { id: 20, code: 'D09', description: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.' },
        { id: 21, code: 'D10', description: 'Pagos por servicios educativos { id: colegiaturas}.' },
        { id: 22, code: 'S01', description: 'Sin efectos fiscales.  ' },
        { id: 23, code: 'CP01', description: 'Pagos' },
        { id: 24, code: 'CN01', description: 'Nómina' }
    ]
}

export function regimes() {
    return [
        { id: 1, code: '601', description: 'Ley General de Personas Morales' },
        { id: 2, code: '603', description: 'Personas Morales con Fines no Lucrativos' },
        { id: 3, code: '605', description: 'Sueldos y Salarios e Ingresos Asimilados a Salarios' },
        { id: 4, code: '606', description: 'Arrendamiento' },
        { id: 5, code: '607', description: 'Régimen de Enajenación o Adquisición de Bienes' },
        { id: 6, code: '608', description: 'Demás ingresos' },
        { id: 7, code: '610', description: 'Residentes en el Extranjero sin Establecimiento Permanente en México' },
        { id: 8, code: '611', description: 'Ingresos por Dividendos { id: socios y accionistas)' },
        { id: 9, code: '612', description: 'Personas Físicas con Actividades Empresariales y Profesionales' },
        { id: 10, code: '614', description: 'Ingresos por intereses' },
        { id: 11, code: '615', description: 'Régimen de los ingresos por obtención de premios' },
        { id: 12, code: '616', description: 'Sin obligaciones fiscales' },
        { id: 13, code: '620', description: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
        { id: 14, code: '621', description: 'Incorporación Fiscal' },
        { id: 15, code: '622', description: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
        { id: 16, code: '623', description: 'Opcional para Grupos de Sociedades' },
        { id: 17, code: '624', description: 'Coordinados' },
        { id: 18, code: '625', description: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas' },
        { id: 19, code: '626', description: 'Régimen Simplificado de Confianza' }
    ]
}

export function unit() {
    let array = [
        { code: 'H87', description: 'Pieza', type: 'Múltiplos / Fracciones / Decimales' },
        { code: 'EA', description: 'Elemento', type: 'Unidades de venta' },
        { code: 'E48', description: 'Unidad de Servicio', type: 'Unidades específicas de la industria (varias)' },
        { code: 'ACT', description: 'Actividad', type: 'Unidades de venta' },
        { code: 'KGM', description: 'Kilogramo', type: 'Mecánica' },
        { code: 'E51', description: 'Trabajo', type: 'Unidades específicas de la industria (varias)' },
        { code: 'A9', description: 'Tarifa', type: 'Diversos' },
        { code: 'MTR', description: 'Metro', type: 'Tiempo y Espacio' },
        { code: 'AB', description: 'Paquete a granel', type: 'Diversos' },
        { code: 'BB', description: 'Caja base', type: 'Unidades específicas de la industria (varias)' },
        { code: 'KT', description: 'Kit', type: 'Unidades de venta' },
        { code: 'SET', description: 'Conjunto', type: 'Unidades de venta' },
        { code: 'LTR', description: 'Litro', type: 'Tiempo y Espacio' },
        { code: 'XBX', description: 'Caja', type: 'Unidades de empaque' },
        { code: 'MON', description: 'Mes', type: 'Tiempo y Espacio' },
        { code: 'HUR', description: 'Hora', type: 'Tiempo y Espacio' },
        { code: 'MTK', description: 'Metro cuadrado', type: 'Tiempo y Espacio' },
        { code: '11', description: 'Equipos', type: 'Diversos' },
        { code: 'MGM', description: 'Miligramo', type: 'Mecánica' },
        { code: 'XPK', description: 'Paquete', type: 'Unidades de empaque' },
        { code: 'XKI', description: 'Kit (Conjunto de piezas)', type: 'Unidades de empaque' },
        { code: 'AS', description: 'Variedad', type: 'Diversos' },
        { code: 'GRM', description: 'Gramo', type: 'Mecánica' },
        { code: 'PR', description: 'Par', type: 'Números enteros / Números / Ratios' },
        { code: 'DPC', description: 'Docenas de piezas', type: 'Unidades de venta' },
        { code: 'xun', description: 'Unidad', type: 'Unidades de empaque' },
        { code: 'DAY', description: 'Día', type: 'Tiempo y Espacio' },
        { code: 'XLT', description: 'Lote', type: 'Unidades de empaque' },
        { code: '10', description: 'Grupos', type: 'Diversos' },
        { code: 'MLT', description: 'Mililitro', type: 'Tiempo y Espacio' },
        { code: 'E54', description: 'Viaje', type: 'Unidades específicas de la industria (varias)' },
    ]

   return array.sort((a, b) => {
        if (a.description < b.description) {
            return -1;
        }
        if (a.description > b.description) {
            return 1;
        }
        return 0;
    });
}