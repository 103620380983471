<template>
    <div class="mt0">
        <w-form ref="formUser" v-model="form.valid" v-model:errors-count="form.errorsCount" @validate="onValidate"
            @success="onSuccess">
            <w-flex wrap class="text-center">
                <div class="my2 pa1 xs12 md10">
                    <w-input v-model="keyword" placeholder="Código/Descripción" @keyup.enter="onSearch"
                        inner-icon-right="fa fa-times" @click:inner-icon-right="keyword = ''" outline>
                    </w-input>
                </div>
                <div class="my2 pa1 xs12 md2">
                    <w-button bg-color="primary" class="fill-width" @click="onSearch">
                        <w-icon class="mr1">fa fa-search</w-icon>
                        Buscar
                    </w-button>
                </div>

            </w-flex>
        </w-form>
        <div class="pa0">
            <!-- <w-list style=" height: 100%;" v-model="selection" :items="table.items" bg-color="blue-light5" color="black"
                selection-color="secondary" hover @item-click="onSetData($event)">
                <template #item="{ item }">
                    <span>{{ item.Name }} <br> <strong> {{ item.Value }} </strong> </span>
                </template>
            </w-list> -->
            <w-table :headers="table.headers" :items="table.items" fixed-headers selectable-rows="1"
                @row-select="onSetData($event)" style="height: 200px" :mobile-breakpoint="700">
            </w-table>
        </div>
        <!-- <w-flex justify-center>
            <div class="md6 pa3">
                <w-button bg-color="secondary" color="white" class="fill-width" @click="onCancel">
                    Cancelar
                </w-button>
            </div>
            <div class="md6 pa3">
                <w-button bg-color="primary" color="white" class="fill-width" @click="onSetData">
                    Agregar
                </w-button>
            </div>
        </w-flex> -->
    </div>
</template>
<script>
import 'animate.css';
import Swal from 'sweetalert2'
import { useFacturamaStore } from '@/stores/facturama.js'
export default {
    data: () => ({
        selection: null,
        keyword: '',
        listServices: [],
        valid: null,
        form: {
            submitted: false,
            sent: false,
            errorsCount: 0
        },
        validators: {
            required: value => !!value || 'El campo es requerido',
            min: (len) => (value) => (value || "").length >= len || `El campo debe tener al menos ${len} caracteres.`,
        },
        table: {
            headers: [
                { label: 'Clave', key: 'Value' },
                { label: 'Descripcion', key: 'Name' }
            ],
            items: [

            ]
        },
        selectionInfo: {}
    }),
    created() {
        this.dialogKweword()
    },
    methods: {
        dialogKweword() {
            Swal.fire({
                title: "Rubro del Servicio",
                input: "text",
                inputAttributes: {
                    autocapitalize: "off"
                },
                confirmButtonText: "Buscar",
                showLoaderOnConfirm: true,
                preConfirm: async (kw) => {
                    this.keyword = kw
                }
            }).then((result) => {
                if (result.isConfirmed && this.keyword != '') {
                    this.onSearch()
                }
            });
        },
        onSearch() {
            let fStore = useFacturamaStore();
            fStore.searchProductServices(this.keyword.toLowerCase()).then(rs => {
                if (rs == 200) {
                    this.table.items = fStore.productServices
                }
            }, () => {
                Swal.fire({
                    icon: "error",
                    title: "Sin retultados",
                    text: "No se encontraron resultados para la búsqueda",
                });
            })
        },
        onSetData(p) {
            console.log(p)
            this.$emit("selected", p.item);
            this.table.items = []
            this.keyword = ''
        },
        onCancel() {
            this.$emit("cancel");
            this.table.items = []
            this.keyword = ''
        }
    }
}
</script>
<style scoped>
.w-input__input-wrap {
    height: 40px !important;
    
}
.w-input__input{
    font-size: 1rem;
}
</style>