import { createRouter, createWebHistory } from 'vue-router'
import LayouView from '../views/LayouView.vue'
import NotFoundView from '@/views/NotFoundView'
import { isAuthenticated } from '@/utils/validate'
import { useCommonStore } from '@/stores/commons.js'

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: LayouView,
    children: [
      {
        path: '',
        meta: { requiresAuth: true },
        name: 'Dashboard',
        component: () => import('../views/DashboardViews/DashboardAdmin.vue')
      },
      //FACTURAS
      {
        path: 'solicitud',
        meta: { requiresAuth: true },
        name: 'Solicitud',
        component: () => import('../views/InvoiceViews/CreateInvoice.vue')
      },
      {
        path: 'facturas',
        component: () => import('@/views/InvoiceViews/MyInvoices.vue'),
        name: 'Facturas',
      },
      {
        path: 'factura/:uuid',
        name: 'Consulta de Factura',
        props: true,
        component: () => import('@/views/InvoiceViews/DetailInvoice.vue'),
      },
      //
      //CLIENTES
      //
      {
        path: 'clientes',
        name: 'Lista Clientes',
        component: () => import('../views/ClientViews/ClientView.vue')
      },
      {
        path: 'clientes/registro',
        name: 'Registro Cliente',
        component: () => import('../views/ClientViews/CreateView.vue')
      },
      //BANCA DE CLIENTE
      {
        path: 'banca',
        name: 'Lista Movimientos',
        component: () => import('../views/TransactionViews/index.vue')
      },
      {
        path: 'banca/registro',
        name: 'Solicitar',
        component: () => import('../views/TransactionViews/CreateView.vue')
      }
    ]
  },

  {
    path: '/login',
    component: () => import('@/views/LoginViews/LoginView.vue'),
    name: 'Login',
    meta: { title: 'Autenticacion', icon: 'mdiAccounts', requiresAuth: false }
  },
  {
    path: '/logout',
    component: () => import('@/views/LoginViews/LogoutView.vue'),
    name: 'Logout',
    meta: { title: 'Cerrando sesión', icon: 'mdiAccounts', requiresAuth: true }
  },
  {
    path: '/forgot_password',
    component: () => import('@/views/LoginViews/forgot_password.vue'),
    name: 'Olvide Contraseña',
    meta: { title: 'Forgot password', icon: 'mdiAccounts', requiresAuth: false }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    meta: { requiresAuth: false },
    component: NotFoundView
  },
]

const router = createRouter({
  scrollBehavior: function () {
    return { x: 0, y: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const store = useCommonStore();

  const items = to.matched.map(route => ({
    text: route.name,
    href: route.path,
  }));

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.path != '/login' && isAuthenticated()) {
      store.setItems(items);
      next();
    }
    else {
      router.replace({ path: '/login' })
    }
  }
  else {
    store.setItems(items);
    next()
  }
})

export default router
