<template>
    <div>
        <div class="message-box">
            <w-transition-fade>
                <w-alert v-if="form.submitted" success no-border class="my0 text-light">
                    El formulario es válido, lista para enviarlo!
                </w-alert>

                <w-alert v-else-if="valid === false" error no-border class="my0 text-light">
                    El formulario tiene {{ form.errorsCount }} errores.
                </w-alert>
            </w-transition-fade>
        </div>

        <w-form ref="formServices" v-model="valid" v-model:errors-count="form.errorsCount" @validate="onValidate"
            @success="onSuccess" class="pt2 pb12">
            <div class="my2">
                <w-input type="number" v-model="item.quantity" label="Cantidad" outline
                    :validators="[validators.required]"> </w-input>
            </div>
            <div class="my2">
                <w-input type="number" v-model="item.product_code" label="Clave SAT" outline> </w-input>
            </div>
            <div class="my2">
                <w-input v-model="item.description" label="Descripción" outline :validators="[validators.required]">
                </w-input>
            </div>
            <div class="my2">
                <w-input type="number" v-model="item.unit_price" label="Precio Unitario" outline> </w-input>
            </div>

            <div class="my2">
                <w-button bg-color="primary" class="fill-width" @click="addSerive">
                    <w-icon class="mr1">fa fa-plus</w-icon>
                    Agregar
                </w-button>
            </div>
        </w-form>
        <w-table :headers="table.headers" :items="table.items" fixed-headers :selectable-rows="false"
            @row-select="onSetData($event)" style="max-height: 200px">
            <template #item-cell.subtotal="{ item, label, header, index }">
                <div class="text-right">
                    <span v-if="item.unit_price">{{ (item.unit_price * item.quantity).toFixed(2) }}</span>
                    <span v-else> - </span>
                </div>
            </template>
            <template #item-cell.delete="{ item, label, header, index }">
                <div class="text-center">
                    <w-button class="ma1 pa1" bg-color="error" icon="fa fa-trash-o" tooltip="Eliminar"
                        :tooltip-props="{ top: true, transition: 'twist', bgColor: 'error' }"
                        @click="onDelete(index)"></w-button>
                </div>
            </template>
        </w-table>
    </div>
</template>
<script>
import 'animate.css';
import Swal from 'sweetalert2'
import { unit } from '@/utils/satCatalogs'
export default {
    data: () => ({
        selection: null,
        valid: null,
        form: {
            submitted: false,
            sent: false,
            errorsCount: 0
        },
        validators: {
            required: value => !!value || 'El campo es requerido',
            min: (len) => (value) => (value || "").length >= len || `El campo debe tener al menos ${len} caracteres.`,
        },
        item: {},
        table: {
            headers: [
                { label: 'Cantidad', key: 'quantity', sortable: false },
                { label: 'Clave SAT', key: 'product_code', sortable: false },
                { label: 'Descripcion', key: 'description', sortable: false },
                { label: 'Precio unitario', key: 'unit_price', sortable: false },
                { label: 'Total', key: 'subtotal', sortable: false },
                { label: '', key: 'delete', sortable: false },
            ],
            items: [],
            pagination: {
                itemsPerPage: 15,
                total: 0
            },
            keyword: '',
            keywordFilter: keyword => item => {
                // Concatenate all the columns into a single string for a faster lookup.
                const allTheColumns = `${item.id} ${item.name} ${item.last_name}`

                // Lookup the keyword variable in the string with case-insensitive flag.
                return new RegExp(keyword, 'i').test(allTheColumns)
            }
        },
        unitList: unit(),
    }),
    created() {
        Swal.fire({
            title: "Servicios/Productos",
            text: "Agrege los servicios y/o productos que requiere que lleve la factura",
            icon: "question"
        });
    },
    methods: {
        addSerive() {
            let fields = ['description', 'quantity'];
            this.$refs.formServices.validate()
            if (fields.every(field => this.item[field])) {               
                this.item.unit_price = this.item.unit_price && this.item.unit_price > 0 ? this.item.unit_price : 0.00
                this.item.product_code = this.item.product_code && this.item.product_code !== '' ? this.item.product_code : '-';
                let it_iv = Object.assign({}, this.item)
                this.table.items.push(it_iv)
                this.$refs.formServices.reset
                this.$emit("added", this.table.items);
                this.item = {}
            }
        },
        onDelete(i) {
            this.table.items.splice(i - 1, 1)
        },
        onCancel() {
            this.$emit("cancel");
            this.table.items = []
            this.keyword = ''
        }
    }
}
</script>
<style scoped>
th {
    text-align: center !important;
}
</style>
