// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD-OI2rUSNqy9qSifkf4Et3gfjzf6q1XjM",
  authDomain: "efs-invoice-client.firebaseapp.com",
  projectId: "efs-invoice-client",
  storageBucket: "efs-invoice-client.appspot.com",
  messagingSenderId: "1017903384542",
  appId: "1:1017903384542:web:8f7f115e432ec31fd4ff4f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default { app }