import { defineStore } from 'pinia'
import facturamaService from '@/stores/services/facturama.services'
export const useFacturamaStore = defineStore("facturama", {
  state: () => ({
    success: null,
    productServices: [],
  }),
  actions: {
    searchProductServices(data) {
      const API = process.env.VUE_APP_FACTURAMA_APIV1
      const PREFIX = `${process.env.VUE_APP_FACTURAMA_CATALOG}${process.env.VUE_APP_FACTURAMA_PRODUCT_SERVICES}`
      try {
        let entity = `${API}${PREFIX}?keyword=${data}`
        return facturamaService.get(entity).then(
          response => {
            console.log("response facturama servcios", response)
            this.productServices = response.data
            return Promise.resolve(response.status)
          },
          (e) => {
            console.error(e)
            return Promise.reject(false)
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    downloadCfdi(data) {
      const API = process.env.VUE_APP_FACTURAMA_APIV1
      let PREFIX = ''
      if (data.format == 'PDF') {
        PREFIX = process.env.VUE_APP_FACTURAMA_CFDI_PDF
      }
      else if (data.format == 'XML') {
        PREFIX = process.env.VUE_APP_FACTURAMA_CFDI_XML
      }
      // let data = "2XNwK2yZo55Wk-eSFqUa9Q2"
      try {
        let entity = `${API}${PREFIX}${data.id}`
        return facturamaService.get(entity).then(
          response => {
            console.log("response facturama post", response)
            return Promise.resolve(response)
          },
          (e) => {
            console.error(e)
            return Promise.reject(false)
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    sendCfdiMail(data) {
      try {
        let entity = `cfdi?cfdiType=issuedLite&cfdiId=${data.id}&email=${data.email}`
        return facturamaService.post2(entity).then(
          response => {
            return Promise.resolve(response)
          },
          (e) => {
            return Promise.reject(false)
          }
        );
      } catch (error) {
        console.error(error);
      }
    }
  },
})