<template>
    <w-card>
        <div class="text-upper title2 text-bold">
            Registro de clientes
        </div>
        <div class="message-box">
            <w-transition-fade>
                <w-transition-fade>
                    <w-alert v-if="valid === false" error no-border class="my0 text-light">
                        El formulario tiene {{ form.errorsCount }} errores.
                    </w-alert>
                </w-transition-fade>
            </w-transition-fade>
        </div>
        <w-form ref="formRegister" v-model="valid" v-model:errors-count="form.errorsCount" @validate="onValidate"
            @success="onSuccess" class="">       
                <div class="xs12 md12 lg12 pa1">
                    <w-card title="Datos fiscales">
                        <w-flex wrap class="text-left">
                            <div class="fill-width mb3">
                                <w-select v-model="item.fiscal_regime" :items="regimeLis" required
                                    :validators="[validators.required]" label="Régimen Fiscal" outline
                                    item-label-key="description" item-value-key="code">
                                </w-select>
                            </div>
                            <div class="fill-width text-left mb3"> <w-input class="my2" v-model="item.name" required
                                label="Nombre corto" :validators="[validators.required]" outline> </w-input>
                            </div>
                            <div class="fill-width text-left mb3"> <w-input class="my2" v-model="item.business_name" required
                                label="Razon social" :validators="[validators.required]" outline> </w-input>
                            </div>
                            <div class="fill-width text-left mb3"> <w-input class="my2" v-model="item.rfc" required
                                label="RFC" :validators="[validators.required, validators.min(12)]" outline>
                                </w-input>
                            </div>
                            <div class="fill-width text-left mb3"> <w-input class="my2" v-model="item.tax_zip_code" required
                                label="Codigo Postal" :validators="[validators.required]" outline> </w-input>
                            </div>
                            <div class="fill-width text-left mb3"><w-input class="my2" v-model="item.address" required
                                label="Dirección" outline> </w-input>
                            </div>
                            <div class="fill-width text-left mb3"><w-input class="my2" v-model="item.phone" required
                                label="Telefono" :validators="[validators.required, validators.min(10)]" outline>
                                </w-input>
                            </div>
                            <div class="fill-width text-left mb3">
                                <w-input class="my2" v-model="item.business_email" required placeholder="Email"
                                    :validators="[validators.required, validators.email]" outline> </w-input>
                            </div>

                            <div class="fill-width mt5">
                                <w-button bg-color="error" class="fill-width" @click="onCancel">Cancelar</w-button>
                            </div>
                            <div class="fill-width mt3">
                                <w-button :disabled="!valid" bg-color="primary" class="fill-width" @click="onSave">Guardar</w-button>
                            </div>
                        </w-flex>
                    </w-card>
                </div>
        </w-form>
    </w-card>
</template>
<script>
import 'animate.css';
import Swal from 'sweetalert2'
import { useClientStore } from '@/stores/clients.js'
import { regimes } from '@/utils/satCatalogs'


const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
export default {
    data: () => ({
        item: {},
        valid: null,
        form: {
            submitted: false,
            sent: false,
            errorsCount: 0
        },
        validators: {
            required: value => !!value || 'El campo es requerido',
            email: value => regex.test(value) || "Ingrese un email válido.",
            min: (len) => (value) => (value || "").length >= len || `El campo debe tener al menos ${len} caracteres.`,
        },
        fileCer: null,
        fileKey: null,
        regimeLis: regimes()
    }),
    methods: {
        onSuccess() {
            console.log("onsuccess")
            setTimeout(() => (this.form.sent = true), 2000)
        },
        onValidate() {
            this.form.sent = false
            this.form.submitted = this.form.errorsCount === 0
        },
        onCancel() {
            this.$refs.formRegister.reset()
            this.$emit("cancel");
            
        },
        onSave() {
            let store = useClientStore();
            this.$refs.formRegister.validate()
            store.create(this.item).then(
                (rs) => {
                    if (rs = 201) {
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Su registro ha sido guardado.",
                            showConfirmButton: false,
                            timer: 1500
                        }).then((r) => {
                            this.$emit("added");
                        });
                    }
                }, (e) => {
                    console.error("la solicitud fallo")
                    Swal.fire({
                        icon: "error",
                        title: "Error en ejecución",
                        text: e,
                    });
                })
        }
    }
}
</script>
<style scoped>
.btn-actions {
    width: 200px;
}</style>
