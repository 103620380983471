<template>
  <div>
    <w-toolbar shadow class="mt0 py1">
      <w-icon xl color="primary" @click="openDrawer = true">
        fa fa-bars
      </w-icon>
      <w-image
        class="ml5"
        :src="require('@/assets/logo.png')"
        height="40"
        width="40"
      >
      </w-image>
      <div class="title3 mx3">Administración y Gestión de Negocios</div>
      <div class="spacer"></div>
      <w-menu
        v-if="
          $waveui.breakpoint.name == 'lg' || $waveui.breakpoint.name == 'xl'
        "
      >
        <template #activator="{ on }">
          <w-button
            v-on="on"
            class="mr2"
            bg-color="success"
            icon="wi-check"
          ></w-button>
        </template>
        <span @click="logout"> Cerrar Sesion </span>
      </w-menu>
      <div
        class="title4 mr1"
        v-if="
          (dsUser && $waveui.breakpoint.name == 'lg') ||
          $waveui.breakpoint.name == 'xl'
        "
      >
        {{ dsUser.name }} <br />
        <strong>{{ dsUser.rol.description }}</strong>
      </div>

      <w-badge class="mr3" bg-color="error" v-if="messagess.length > 0">
        <template #badge>{{ messagess.length }}</template>
        <w-button icon="fa fa-bell" text lg></w-button>
      </w-badge>
    </w-toolbar>
    <w-drawer
      v-model="openDrawer"
      left
      :no-overlay="noOverlay"
      :overlay-color="overlayColor"
      :width="widthBP"
      :responsive="{ sm: true }"
    >
      <w-button
        @click="openDrawer = false"
        sm
        outline
        absolute
        round
        icon="wi-cross"
      >
      </w-button>
      <w-image
        class="mt10"
        :src="require('@/assets/banners/eficent-drawer.jpg')"
        width="100%"
        height="100"
      >
      </w-image>
      <w-list
        v-model="selection"
        :items="menuDrawer"
        color="blue-dark1"
        selection-color="orange-dark1"
        :multiple="false"
        @item-click="itemClicked(selection)"
        class="mt2 grow"
      >
      </w-list>
    </w-drawer>
    <w-breadcrumbs :items="urls" class="pa2 red--color-bg">
      <template #item="{ item, isLast }">
        <w-tag
          :color="isLast ? 'cyan-dark1' : 'orange-dark1'"
          round
          outline
          v-html="item.text"
        >
        </w-tag>
      </template>
    </w-breadcrumbs>
    <router-view />
  </div>
</template>
<script>
import "font-awesome/css/font-awesome.min.css";
import { useCommonStore } from "@/stores/commons.js";
import { useAccountStore } from "@/stores/accounts";

export default {
  data: () => ({
    // dsUser: null,
    openDrawer: false,
    noOverlay: false,
    overlayColor: "",
    menuDrawer: [
      { label: "Inicio", value: "/" },
      { label: "Facturas", value: "facturas" },
      { label: "Mis Clientes", value: "clientes" },
      { label: "Mi Banca", value: "banca" },
      { label: "Perfil", value: "perfil" },
      { label: "Cerrar Sesion", value: "logout" }
    ],
    selection: "/",
    itemNav: "/",
  }),
  computed: {
    messagess() {
      return [];
    },
    urls() {
      const store = useCommonStore();
      return store.items;
    },
    dsUser() {
      const Ustore = useAccountStore();
      let u = Ustore.userAccount;
      return u;
    },
    widthBP() {
      let w = "50%";
      switch (this.$waveui.breakpoint.name) {
        case "xs":
          w = "85%";
          break;
        case "sm":
          w = "45%";
          break;
        case "md":
          w = "45%";
          break;
        case "lg":
          w = "20%";
          break;
        case "xl":
          w = "20%";
          break;
      }
      return w;
    },
  },
  methods: {
    itemClicked(item) {
      this.$router.push({ path: `/${this.selection}` });
      this.selection = null;
      this.openDrawer = false;
    },
    getDataUser() {
      const Ustore = useAccountStore();
      this.dsUser = Ustore.userAccount;
    },
    logout() {
      const Ustore = useAccountStore();
      Ustore.logout();
      this.$router.replace("/login");
    },
  },
};
</script>
<style lang="css" scope>
.w-drawer {
  display: flex;
  flex-direction: column;
}
</style>