

export async function getDevice() {
    try {
        const isThere = 'navigator' in window;
        let os = "";
        const userAgent = isThere && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
        const vendor = isThere && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
        const appVersion = isThere && 'appVersion' in navigator && navigator.appVersion.toLowerCase() || '';
        const platform = isThere && 'platform' in navigator && navigator.platform.toLowerCase() || '';

        if (/iphone/i.test(userAgent) || /ipad/i.test(userAgent) || /ipod/i.test(userAgent)) os = 'ios';
        if (/android/i.test(userAgent)) os = 'android'
        if (/win/i.test(appVersion) && /phone/i.test(userAgent)) os = 'windowsPhone'
        if (/mac/i.test(appVersion)) os = 'MacOSX'
        if (/win/i.test(appVersion)) os = 'windows'
        if (/linux/i.test(appVersion)) os = 'linux'

        const respuesta = await fetch('https://api.ipify.org?format=json');
        const datos = await respuesta.json();
        let ip = datos.ip

        let data = {
            os: os,
            userAgent: userAgent,
            vendor: vendor,
            appVersion: appVersion,
            platform: platform,
            ip: ip,
        }
        return JSON.stringify(data)

    } catch (error) {
        console.error(error)
        return null
    }

}

