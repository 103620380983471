<template>
  <div class="c-toats">
    <w-alert v-if="toast" success icon-outside>
    El formulario es válido, lista para enviarlo!
  </w-alert>
  </div>

  <!-- <w-alert class="pa2" color="success" border-left>
    This is a normal alert with a left border.
  </w-alert> -->
</template>
  
<script>
import { useToastStore } from '@/stores/toast.js';

export default {
  name: "toast",
  props: {
    toast: null,
  },
  data: () => ({
    store: useToastStore(),
  }),
  created() {
    setTimeout(() => {
      this.dismissToast();
    }, 5000);
  },
  methods: {
    dismissToast() {
      this.store.clearToast(this.toast.title)
    },
  },
};
</script>
  
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.9s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.c-toats{
  background-color: #fff;
}
</style>