import axios from 'axios';
import 'animate.css';
import Swal from 'sweetalert2'

const API_URL = process.env.VUE_APP_FACTURAMA_HOST
const faxios = axios.create()

faxios.interceptors.request.use(function (config) {
    let token =  btoa(`${process.env.VUE_APP_FACTURAMA_USER}:${process.env.VUE_APP_FACTURAMA_PSW}`)
    config.withCredentials = true;
    config.headers.Authorization = `Basic  ${token}`;
    return config

}, function (err) {
    return Promise.reject(err);
});
faxios.interceptors.response.use(
    response => {
        console.log("facturama ok: ", response)
        return Promise.resolve(response);
    },
    error => {
        Swal.fire({
            icon: "error",
            title: error.response.data.Message,
            text: error.response.data.ModelState.Rfc,
            showClass: {
                popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
                `
            },
            hideClass: {
                popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
`
            },
            customClass: {
                container: "mySwal",
            },
        });
        return Promise.reject(error);
    }
);

class FacturamaService{
    post(payload, entity){
        return faxios.post(`${API_URL}${entity}`, payload);
    }
    get(entity) {        
        return faxios.get(`${API_URL}${entity}`);
    }
}

export default new FacturamaService();