const NAME_APP = process.env.VUE_APP_APPLICATION_NAME;

export function isAuthenticated() {
    let loggedIn = JSON.parse(localStorage.getItem(NAME_APP));
    if (loggedIn === null) {
      return false
    }
    else {
      return true
    }
  }