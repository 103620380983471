import { createApp } from 'vue'
import App from './App.vue'
import { useCommonStore } from '@/stores/commons'
import { createPinia } from 'pinia'
import './registerServiceWorker'
import router from './router'
import WaveUI from 'wave-ui'
import 'wave-ui/dist/wave-ui.css'
import 'sweetalert2/src/sweetalert2.scss'
import interceptor from './utils/interceptor';
// import VueStepWizard from 'vue-step-wizard'
// import 'vue-step-wizard/dist/vue-step-wizard.css'

const pinia = createPinia()

createApp(App).use(WaveUI, {
    colors: {
      primary: '#348e91',
      secondary: '#f27528',
      accent: '#18b6d2',
      error: '#f44336',
      info: '#2196f3',
      success: '#4caf50',
      warning: '#ff9800',
    },
    theme: 'light'
  }).use(router).use(pinia).mount('#app')
  //.use(VueStepWizard)
  const store = useCommonStore();
  interceptor(store, router)
