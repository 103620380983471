<template>
  <w-transition-fade>
    <div class="toasts-wrapper" v-if="toasts && toasts.length > 0">
      <Toast v-for="(t, index) of toasts" :key="index" :toast="t"> </Toast>
    </div>
  </w-transition-fade>
</template>
  
<script>

import Toast from "./components/toast.vue";
import { useToastStore } from '@/stores/toast.js';

export default {
  name: "Toasts",
  components: { Toast },
  data: () => ({
    store: useToastStore(),
  }),
  computed: {
    toasts() {
      return this.store.toasts;
    },
  }
};
</script>
  
<style>
.toasts-wrapper {
  z-index: 999999;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100px;
}

@media (min-width: 768px) {
  .toasts-wrapper {
    width: 30%;
    top: 1rem;
    right: 1rem;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .9s
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0
}
</style>